import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (pathname.startsWith("/game/")) {
            let tg = window.Telegram.WebApp;
            if (tg.initDataUnsafe.user != null) {
                var BackButton = tg.BackButton;
                BackButton.show();
                BackButton.onClick(function () {
                    navigate("/" + window.location.search);

                    BackButton.hide();
                });
                console.log("backbutton is... ");
                console.log(BackButton);
                tg.onEvent("backButtonClicked", function () {
                    navigate("/" + window.location.search);
                    BackButton.hide();
                });
            }
            document.body.classList.add("game-open");
        } else {
            let tg = window.Telegram.WebApp;
            if (tg.initDataUnsafe.user != null) {
                var BackButton = tg.BackButton;
                BackButton.hide();
            }

            document.body.classList.remove("game-open");
        }

        if (document.body.offsetWidth < 960) {
            if (pathname == "/profile/wallet/wallets") {
                if (
                    document.querySelector(".profile-menu-list .selected") !=
                    null
                )
                    document
                        .querySelector(".profile-menu-list .selected")
                        .classList.remove("selected");

                if (document.getElementById("mobileWalletToggle") != null)
                    document
                        .getElementById("mobileWalletToggle")
                        .classList.add("open");
                if (document.getElementById("mobileMainToggle") != null)
                    document
                        .getElementById("mobileMainToggle")
                        .classList.remove("open");
            }

            if (pathname == "/games") {
                if (
                    document.querySelector(".profile-menu-list .selected") !=
                    null
                )
                    document
                        .querySelector(".profile-menu-list .selected")
                        .classList.remove("selected");

                if (document.getElementById("mobileWalletToggle") != null)
                    document
                        .getElementById("mobileWalletToggle")
                        .classList.remove("open");
                if (document.getElementById("mobileMainToggle") != null)
                    document
                        .getElementById("mobileMainToggle")
                        .classList.add("open");
            }
        }
        document.querySelector(".container-main").scrollTo(0, 0);
    }, [pathname]);

    return null;
}

import { useState } from "react";
import ProfileMenu from "../../components/ProfileMenu";
import React from "react";
import { useTranslation } from "react-i18next";
export default function Vip () {
    const defaultPrizeCurrency = 'EUR';
    const ref = React.createRef();
    const [t] = useTranslation();
    
    const [scrollValue,setScrollValue] = useState(0);
    const vipStatusList = [
        {num: '01', type: 'Bronze',fastWithdraw: false, withdrawalLimit: 25000, cashbackValue: 10, prize: 100,birthdayGifts:false,fullDaySupport:true,dailyReload:false,vipManager:false},
    {num: '07', type: 'Silver',fastWithdraw: false, withdrawalLimit: 100000, cashbackValue: 10, prize: 100,birthdayGifts:false,fullDaySupport:true,dailyReload:false,vipManager:false},
        {num: 13, type: 'Gold',fastWithdraw: false, withdrawalLimit: 150000, cashbackValue: 10, prize: 100,birthdayGifts:true,fullDaySupport:true,dailyReload:false,vipManager:false},
        {num: 19, type: 'Platinum',fastWithdraw: true, withdrawalLimit: 500000, cashbackValue: 10, prize: 100,birthdayGifts:true,fullDaySupport:true,dailyReload:false,vipManager:true},
      
    ]
const progress = 50;
    return(<>
        <div className="Profile-Block">
            <ProfileMenu url={'/profile/vip'}/>
            <div className="Profile-Block__container">
    <h1>{t('vip_club')}</h1>
            <div className="Profile-Block__fields">
          <div className="user-vip-profile">
            <div>
                <img src="/images/vip/ri_13_01.png" alt="" />
                <div>
                    <h6>Username</h6>
                    <p>Bronze 1 </p>
                </div>
            </div>
            <section>
<img src="/images/vip/ri_13_01.png" alt="" />
<div>
    <span>250 XP / 500 XP (50%)</span>
    <nav>
        <span style={{width: progress+'%'}}></span>
    </nav>
</div>
<img src="/images/vip/ri_13_02.png" alt="" />
            </section>
           </div>
           <nav style={{marginBottom:'-12px'}}>
            <p>{t('levels')}</p>

           </nav>
           <div className="user-vip-levels">
            <div ref={ref} style={{transform: "translateX(-"+(scrollValue)+"px)"}}>
            { vipStatusList.map(elem=><div>
                    <img src={"/images/vip/ri_13_"+(elem.num)+".png"} alt="" />
                    <h4>{elem.type}</h4>
<nav>
    <p>{t('daily_limit')}</p>
    <span>{elem.withdrawalLimit.toLocaleString('en-US')} {defaultPrizeCurrency}</span>
</nav>

<nav>
    <p>{t('birthday_gifts')}</p>
    <span>{elem.birthdayGifts ? '+' : '-'}</span>
</nav>
<nav>
    <p>{t('fastWithdraw')}</p>
    <span>{elem.fastWithdraw ? '+' : '-'}</span>
</nav>
<nav>
    <p>{t('fullDaySupport')}</p>
    <span>{elem.fullDaySupport ? '+' : '-'}</span>
</nav>

<nav>
    <p>{t('vipManager')}р</p>
    <span>{elem.vipManager ? '+' : '-'}</span>
</nav>
                </div> 
)}
            </div>
        
           </div>
           <h2>{t('vip_rules')}</h2>
           <p className="longtext">{t('vip_rules_text')}  </p>
            </div>
        </div>
        </div>
        
        </>)
}
import { useAtom } from "jotai"
import { useEffect, useState } from "react"
import {
    authAtom,
    balanceAtom,
    idAtom,
    isTelegramAtom,
    needFinishReg,
    notificationsAtom,
    payDataAtom,
    socketAtom,
    telegramUsernameAtom,
    usernameAtom,
    wheelSpinnedAtom
} from "../App"
import axios from "axios"
import { toast } from "react-toastify"
import { io } from "socket.io-client"
import {
    useNavigate
} from "react-router-dom";

export default function AuthTg() {
    const [, setNeedfill] = useAtom(needFinishReg)
    const [payData, setPayData] = useAtom(payDataAtom)
    const [wheelSpinned, setWheelSpinned] = useAtom(wheelSpinnedAtom)
    const [, setNotications] = useAtom(notificationsAtom)
    const [, setTelegram] = useAtom(isTelegramAtom)
    const [, setUsername] = useAtom(usernameAtom)
    const [, setId] = useAtom(idAtom)
    const [, setTelegramUsername] = useAtom(telegramUsernameAtom)
    const [balance, setBalance] = useAtom(balanceAtom)
    const [, setAuth] = useAtom(authAtom)
    const [, setSocket] = useAtom(socketAtom)
    const [LoadedAuth, setLoaded] = useState(false)
    const navigate = useNavigate();


    const loadSocket = () => {
        var d = io.connect(process.env.REACT_APP_SOCKET, {
            auth: {
                token: localStorage.getItem("accessToken"),
            },
            secure: true,
        })
        d.on("payment.crypto.success", (data) => {
            toast.success("Платеж через криптовалюту зачислен!")
        })
        d.on("payment.crypto.pending", (data) => {
            toast.info("Платеж через криптовалюту создан и находится в ожидании оплаты!")
        })
        d.on("payment.bank.success", (data) => {
            if (!payData.id == data.transaction_id) {
                return
            }

            setPayData((payData) => ({ ...payData, open: true, loading: "success" }))
            setTimeout(() => {
                setPayData(() => ({ open: false }))
            }, 4000)
        })
        d.on("payment.bank.cancelled", (data) => {
            if (!payData.id == data.transaction_id) {
                return
            }

            setPayData((payData) => ({ ...payData, open: true, loading: "cancel" }))
            setTimeout(() => {
                setPayData({ open: false })
            }, 4000)
        })
        d.on("balanceUpdated", (data) => {
            setBalance({ ...balance, RUB: data.balance })
        })
        setSocket(d)
    }
    const LoadAuthData = async () => {
        let res = await axios
            .get(process.env.REACT_APP_API + "/user/me", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("accessToken"),
                },
            });


        if(res.status = 200) {
            setUsername(res.data.user.name)
            setId(res.data.user.id)
            setNeedfill(!res.data.user.isProfileFilled)
            setBalance({ RUB: res.data.user.balance, USD: 0, EUR: 0 })
            setWheelSpinned(res.data.user.wasBonusActivated)
            let bonus = (await axios
                .get(process.env.REACT_APP_API + "/games/bonus-info")).data.isWheelActive;

            if ((localStorage.getItem("referralId") || localStorage.getItem("referralInvitationId")) && !res.data.user.wasBonusActivated && bonus) {
                navigate("/spin");
            }
            setAuth(true)
            loadSocket()
            setNotications(res.data.notications[0])
        }
    }
    let tg = window.Telegram.WebApp 


    useEffect(() => {
        let app = window.Telegram.WebApp;
        app.ready?.()
        app.isClosingConfirmationEnabled = true
        app.allow_vertical_swipe = false
        app.disableVerticalSwipes?.()
        app.expand?.()

        if (tg.initDataUnsafe.user != null && !LoadedAuth) {

            console.log(tg.initDataUnsafe, app.initDataUnsafe, window.location.search, window.location.href)
            setTelegram(true)

            setTelegramUsername(tg.initDataUnsafe.user.first_name + " " + tg.initDataUnsafe.user.last_name)

            setLoaded(true)

            var data = {
                first_name: tg.initDataUnsafe.user.first_name,
                last_name: tg.initDataUnsafe.user.last_name,
                id: tg.initDataUnsafe.user.id,

                username: tg.initDataUnsafe.user.username,
                photo_url: tg.initDataUnsafe.user.photo_url,
                auth_date: tg.initDataUnsafe.auth_date,
                hash: tg.initDataUnsafe.hash,
            }
            if (localStorage.getItem("referralId")) {
                data.user_referral_id = localStorage.getItem("referralId")
            }
            if (localStorage.getItem("referralInvitationId")) {
                data.referral_invitation_id = localStorage.getItem("referralInvitationId")
            }
            axios
                .post(process.env.REACT_APP_API + "/auth/telegram", data)
                .then(async (response) => {
                    setAuth(true)

                    localStorage.setItem("accessToken", response.data.tokens.accessToken)

                    await LoadAuthData()
                })
                .catch(() => {})





           
        }
    }, [])

    return <></>
}

import ProfileMenu from "../../components/ProfileMenu";
import { useTranslation } from "react-i18next";
import { notificationsAtom } from "../../App";
import { useAtom } from "jotai";
export default function Notifications() {
  const [t, i18n] = useTranslation();
  const [notificationsList, setNotificationList] = useAtom(notificationsAtom);
  return (
    <>
      <div className="Profile-Block">
        <ProfileMenu url={"/profile/notifications"} />
        <div className="Profile-Block__container">
          <h1>{t("notifications")}</h1>
          <div className="Profile-Block__fields">
            <div className="notifications-block">
              {notificationsList.length == 0 ? (
                <p>Нет новых уведомлений</p>
              ) : (
                notificationsList.map((elem) => (
                  <>
                    <div className={!elem.isViewed ? "new" : ""}>
                      <p>{elem.message}</p>
                      
                    </div>
                  </>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

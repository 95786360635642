import ProfileMenu from "../../components/ProfileMenu";
import { useTranslation } from "react-i18next";
import { useState } from "react";
export default function Bonuses (){
const [salesList,setSalesList] = useState([
    {image: 'gift.png', title: '20%', desc: 'Бонус на первый депозит больше 5000 руб' },
    {image: 'gift.png', title: '20%', desc: 'Бонус на первый депозит больше 5000 руб' },
    {image: 'gift.png', title: '20%', desc: 'Бонус на первый депозит больше 5000 руб' }
])

const [promocode,setPromocode] = useState(null);
    const { t, i18n } = useTranslation();
    return(<>
    <div className="Profile-Block">
        <ProfileMenu url={'/profile/bonuses'}/>
        <div className="Profile-Block__container">
  
<h1>{t('bonuses')}</h1>
        <div className="Profile-Block__fields">
        <nav>
            <p>{t('use_promocode')}</p>
            <div className="promocode-field">
                <input type="text" value={promocode} onChange={e=>{setPromocode(e.value)}} placeholder="Промокод"/>
                <a className="btn check-btn"> <img src="/images/check.svg" alt="" /> </a>
            
            </div>

            <p>{t('available_promotions')}</p>
        
        </nav>
        <div className="sales-list">
{salesList.map(item=><div><img src={"/images/"+item.image} alt="" /> <h5>{item.title}</h5> <p>{item.desc}</p> </div>)}
            </div>
        </div>
        </div>
    </div>
    </>)
}
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { formatItem } from "../api/Helper";

import {
    gamesCategoriesAtom,
    gamesAtom,
    currentGamesCategoriesAtom,
    gamesOverallAtom,
} from "../App";
import { useAtom, useSetAtom, useAtomValue } from "jotai";

export default function GameItem({
    auth,
    img,
    name,
    label,
    id,
    setActualGameLink,
    userId,
    favorite,
}) {
    const navigate = useNavigate();
    const [t, i18n] = useTranslation();

    const [fav, setFav] = useState(favorite);
    const openGame = () => {
        if (!auth) {
            toast.error(t("need_login"));
            return;
        }

        navigate("/game/" + id + window.location.search);
    };

    // const setGamesCategories = useSetAtom(gamesCategoriesAtom);
    // const setGamesCategories = useSetAtom(wgamesCategoriesAtom);
    // const gamesCategories = useAtomValue(gamesCategoriesAtom);
    const [games, setGames] = useAtom(gamesAtom);
    const currentGamesCategories = useAtomValue(currentGamesCategoriesAtom);
    const [gamesCategories, setGamesCategories] = useAtom(gamesCategoriesAtom);
    const [gamesOverAll, setGamesOverall] = useAtom(gamesOverallAtom);

    async function handleFavorite(e) {
        e.preventDefault();
        e.stopPropagation();

        const response = await axios.post(
            process.env.REACT_APP_API + `/games/${id}/like`,
            {},
            {
                headers: {
                    Authorization:
                        "Bearer " + localStorage.getItem("accessToken"),
                },
            },
        );

        const liked = response.data.id;

        setFav((x) => liked);

        //want to change this setGamesCategories
        // and want to change setGames for delete

        setGamesOverall((x) => {
            const idx = x.findIndex((y) => y.id == id);
            x[idx].isLiked = liked;
            return x;
        });

        if (liked) {
            if (!currentGamesCategories) {
                setGamesCategories((x) => {
                    if (!x["favourite"].some((y) => y.id == id)) {
                        x["favourite"].push({
                            id,
                            img,
                            isLiked: liked,
                            label,
                            name,
                        });
                    }

                    return x;
                });
            } else {
                setGamesCategories((x) => {
                    let ids = x[currentGamesCategories].findIndex(
                        (y) => y.id == id,
                    );
                    x[currentGamesCategories][ids].isLiked = liked;

                    if (!x["favourite"].some((y) => y.id == id)) {
                        x["favourite"].push({
                            id,
                            img,
                            isLiked: liked,
                            label,
                            name,
                        });
                    }

                    return x;
                });
            }
        } else {
            if (!currentGamesCategories) {
                setGamesCategories((x) => {
                    if (x["favourite"].some((y) => y.id == id)) {
                        x["favourite"] = x["favourite"].filter(
                            (y) => y.id != id,
                        );
                    }

                    return x;
                });
            } else if (currentGamesCategories == "favourite") {
                setGames((x) => {
                    return x.filter((y) => y.id != id);
                });

                setGamesCategories((x) => {
                    for (let y of Object.keys(x)) {
                        if (y !== "favourite") {
                            for (let i in x[y]) {
                                if (x[y][i].id == id) {
                                    x[y][i].isLiked = liked;
                                }
                            }
                        }
                    }

                    if (x["favourite"].some((y) => y.id == id)) {
                        x["favourite"] = x["favourite"].filter(
                            (y) => y.id != id,
                        );
                    }

                    return x;
                });
            } else {
                setGamesCategories((x) => {
                    let ids = x[currentGamesCategories].findIndex(
                        (y) => y.id == id,
                    );
                    x[currentGamesCategories][ids].isLiked = liked;

                    if (x["favourite"].some((y) => y.id == id)) {
                        x["favourite"] = x["favourite"].filter(
                            (y) => y.id != id,
                        );
                    }

                    return x;
                });
            }
        }
    }

    return (
        <a onClick={openGame} data-gameId={id} className="games-list__item">
            <img src={img} loading="lazy" alt={id} />
            <h4>{name}</h4>
            <span>{formatItem(label)}</span>

            {fav ? (
                <svg
                    onClick={handleFavorite}
                    className={"favorite-game"}
                    width={15}
                    height={15}
                    viewBox="0 0 32 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M15.9864 26.6263C15.1645 26.6277 14.3751 26.314 13.7896 25.7532L2.84726 15.1148C2.0397 14.3484 1.39672 13.4338 0.955586 12.4239C0.514456 11.414 0.283966 10.329 0.277484 9.23184C0.271003 8.13468 0.488659 7.0472 0.917829 6.03246C1.347 5.01772 1.97914 4.09593 2.77758 3.32056C3.57603 2.54518 4.52487 1.93165 5.56909 1.51557C6.61332 1.09949 7.73213 0.889123 8.86062 0.896681C9.98911 0.90424 11.1048 1.12957 12.1431 1.55961C13.1813 1.98964 14.1214 2.61582 14.9088 3.40181L15.9864 4.44953L17.0779 3.40181C17.8684 2.63177 18.8073 2.02085 19.8409 1.60402C20.8746 1.1872 21.9826 0.972641 23.1016 0.972641C24.2207 0.972641 25.3287 1.1872 26.3624 1.60402C27.396 2.02085 28.335 2.63177 29.1255 3.40181C30.7184 4.95749 31.6127 7.06322 31.6127 9.25831C31.6127 11.4534 30.7184 13.5591 29.1255 15.1148L18.1831 25.7532C17.5976 26.314 16.8083 26.6277 15.9864 26.6263Z"
                        fill="url(#paint0_linear_132_22)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_132_22"
                            x1="3.5"
                            y1="22.5"
                            x2="6.09638"
                            y2="-1.94566"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="#71CED0" />
                            <stop offset="0.466157" stop-color="#5E67EE" />
                            <stop offset="1" stop-color="#622EC6" />
                        </linearGradient>
                    </defs>
                </svg>
            ) : (
                <svg
                    onClick={handleFavorite}
                    className={"favorite-game"}
                    width={15}
                    height={15}
                    viewBox="0 0 33 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_273_1337)">
                        <path
                            d="M16.4856 27.3923C15.638 27.3938 14.824 27.0611 14.2202 26.4662L2.93587 15.1819C2.10308 14.369 1.44 13.3988 0.985082 12.3276C0.530167 11.2564 0.292474 10.1055 0.28579 8.94177C0.279106 7.778 0.503564 6.62449 0.946145 5.54814C1.38873 4.47179 2.04062 3.49404 2.86401 2.67159C3.68741 1.84914 4.66591 1.19836 5.74276 0.757016C6.81962 0.315669 7.97339 0.0925326 9.13715 0.10055C10.3009 0.108568 11.4515 0.347581 12.5222 0.803723C13.5928 1.25987 14.5623 1.92406 15.3743 2.75778L16.4856 3.86911L17.6112 2.75778C18.4264 1.94098 19.3947 1.29297 20.4606 0.850838C21.5266 0.408703 22.6692 0.181122 23.8232 0.181122C24.9772 0.181122 26.1199 0.408703 27.1859 0.850838C28.2518 1.29297 29.2201 1.94098 30.0353 2.75778C31.678 4.40791 32.6002 6.64149 32.6002 8.96985C32.6002 11.2982 31.678 13.5318 30.0353 15.1819L18.751 26.4662C18.1472 27.0611 17.3332 27.3938 16.4856 27.3923ZM9.16218 2.31609C7.83705 2.30588 6.53905 2.69158 5.43452 3.42377C4.32999 4.15597 3.46925 5.2013 2.96262 6.4258C2.45598 7.6503 2.32652 8.9982 2.59083 10.2967C2.85513 11.5953 3.50117 12.7853 4.44614 13.7144L15.7305 24.9987C15.9308 25.1988 16.2024 25.3112 16.4856 25.3112C16.7688 25.3112 17.0404 25.1988 17.2407 24.9987L28.525 13.7144C29.1799 13.1055 29.7054 12.371 30.07 11.5545C30.4346 10.738 30.6309 9.85647 30.6472 8.96245C30.6634 8.06842 30.4994 7.18029 30.1647 6.3511C29.8301 5.52191 29.3317 4.76868 28.6994 4.1364C28.0672 3.50413 27.3139 3.00578 26.4847 2.67113C25.6555 2.33648 24.7674 2.17239 23.8734 2.18867C22.9794 2.20495 22.0978 2.40125 21.2813 2.76587C20.4649 3.13048 19.7303 3.65592 19.1214 4.31079L17.6967 5.73558C17.3613 6.06281 16.9114 6.24597 16.4428 6.24597C15.9743 6.24597 15.5244 6.06281 15.189 5.73558L13.7642 4.31079C12.5492 3.07102 10.8977 2.35521 9.16218 2.31609Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_273_1337">
                            <rect
                                width="32.3143"
                                height="27.2857"
                                fill="white"
                                transform="translate(0.285645 0.142883)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            )}
            {formatItem(label).toLowerCase().includes("pgsoft") &&
                formatItem(label).toLowerCase() != "pgsoft sl" && (
                    <div
                        style={{
                            position: "absolute",
                            bottom: "7px",
                            right: "10px",
                            fontSize: "12px",
                        }}
                        className="vpn"
                    >
                        VPN
                    </div>
                )}
        </a>
    );
}
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import GameItem from "./GameItem";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import {
    actualGameLinkAtom,
    authAtom,
    constructGameObject,
    gamesAtom,
    idAtom,
} from "../App";
import { useAtom } from "jotai";
export default function GameList({
    setLoadMoreVisible,
    search,
    provider,
    maxRows,
}) {
    const [games] = useAtom(gamesAtom);
    const [id, setId] = useAtom(idAtom);
    const [auth, setAuth] = useAtom(authAtom);
    const [actualGameLink, setActualGameLink] = useAtom(actualGameLinkAtom);

    const [t, i18n] = useTranslation();
    function GameRow({ data }) {
        return (
            <div>
                {data.map((elem) => (
                    <GameItem
                        id={elem.id}
                        userId={id}
                        auth={auth}
                        setActualGameLink={setActualGameLink}
                        label={elem.label}
                        img={elem.img}
                        name={elem.name}
                        // favorite={elem?.favorite}
                        favorite={elem?.isLiked}
                    />
                ))}
            </div>
        );
    }

    var formatedGames = [];
    const [gamesToRender, setGamesToRender] = useState([]);

    const formatGames = (provider, maxRows, search) => {
        var valid = [];

        for (var i = 0; i < games.length; i++) {
            if (provider === false || games[i].label === provider) {
                if (
                    search === false ||
                    games[i].name
                        .toLowerCase()
                        .includes(search.toLowerCase().trim())
                ) {
                    valid.push(constructGameObject(games, i));
                }
            }
        }

        setLoadMoreVisible(true);

        for (var i = 0; i < maxRows * 9; i++) {
            if (valid.length == i) {
                setLoadMoreVisible(false);
                break;
            }
            if (formatedGames.length == 0 || formatedGames.at(-1).length == 9) {
                if (formatedGames.length < maxRows) formatedGames.push([]);
                else break;
            }
            var a = formatedGames.at(-1);

            a.push(valid[i]);
        }

        setGamesToRender(formatedGames);
    };

    useEffect(() => {
        formatGames(provider, maxRows, search);
    }, [provider, search, maxRows, games]);

    return (
        <>
            {gamesToRender.length == 0 ? (
                <p>{t("no_games_found")}</p>
            ) : (
                gamesToRender.map((item) => <GameRow data={item} />)
            )}
        </>
    );
}
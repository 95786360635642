import { useAtom } from "jotai";
import { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authAtom, authLoadedAtom } from "../App";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

type ProtectedRouteProps = PropsWithChildren<{}>;

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { t } = useTranslation(); 
  const navigate = useNavigate();
  const [auth] = useAtom(authAtom); 
  const [authLoaded] = useAtom(authLoadedAtom); 

  useEffect(() => {
    if (!authLoaded) {
      return;
    }

    if (!auth && authLoaded) {
      toast.error(t("need_login"));
      navigate("/");
    }
  }, [auth, authLoaded, navigate, t]); 

  if (!authLoaded) {
    return null;
  }

  return children;
}
